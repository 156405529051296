<template>
        <div id="questioncard"  :class='{Q: !result}'>
            <component v-bind:is="nextpage[currentStep].component" @overridecss='function1'/>
            <button class="next_btn" v-on:click="next($event), proceedSurvey()" v-if="currentStep == 0">Start Now</button>
        </div>
</template>


<script>
import WelcomePage from './WelcomePage';
import SurveyQuestion from './SurveyQuestion';
// import ResultPage from './components/steps/ResultPage.vue'


export default {
    name: 'MultiPage',
    components: [
        WelcomePage, SurveyQuestion
    ],
    data() {
        return{

            currentStep: 0,
            nextpage: [
                {
                    component: WelcomePage
                },
                {
                    component: SurveyQuestion
                }
            ],
            slug: ''
        }
    },
    methods:{
        next(){
            this.currentStep += 1
        },
        updateFormValue(payload){
            this.formValue = {
                ...this.formValue,
                [payload.label]: payload.data
            }
        },
        submit(){
            console.log(this.formValue)
        },
        proceedSurvey(){
            this.slug = location.pathname;
            console.log(window.location.pathname)
        },
        function1(value) {
            this.$emit('overridecss', value)
        }
    }
}




</script>

<style scoped>
.Q
{
    margin: 100px auto 30px auto;
    min-width: 20%;
    max-width: 52%;
    height: 100%;
    background-color: white;
    background-position: center;
    background-size: cover;
    border-radius: 25px;
    /* padding: 40px 30px 20px 30px; */
    box-shadow: 0 0 25px 0px rgba(0,0,0,0.5);
}

.next_btn
{
    background-color: #110093;
    border: none;
    padding: 10px 40px;
    border-radius: 30px;
    font-size: 20px;
    font-weight: bold;
    margin: 20px 10px 32px 10px;
    color: white;
}

.next_btn:hover
{
    transform: scale(106%);
}

@media(max-width: 850px)
{
    .Q
    {
        min-width: 90%;

    }
}

</style>