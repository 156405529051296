<template>
    <div style="justify-content:center" class="Q1">
        <div class="resultrow">
            <div class="column1">
                <img :src="storedresult.image_path" class="title_img">
                <p style="color: white; margin-top: 30px; text-align: center; font-size: 22px;">You’re a...</p>
                <h2 style="color: white"><b>{{storedresult.title}}</b></h2>
                <!-- <p style="color: white; margin-top: 35px; font-size: 22px">Thank you for your participation!</p> -->
            </div>
            <div class="column2">
                <div class="card">
                    <div>
                        <div style="margin-bottom: 45px">
                            <h4 style="text-align: left; font-weight: 550; margin-bottom: 30px"><b>What does this mean?</b></h4>
                            <div v-html="storedresult.description">
                            </div>
                        </div>
                        <div class="card-column" v-for="content in storedresult.contents" :key="content.id">
                            <img :src="content.content_img" class="card_img">
                            <p style="padding:0px 10px"> {{content.description}} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p class="fortextonly">Thank you for your participation!</p>
    </div>
</template>

<script>


export default {
  name: 'ResultPage',
  props: {
     storedresult: Object,
  },
  data(){
    return {
        results: ''
    }
  },
  mounted: function() {
       console.log(this.storedresult)
   }

}

</script>



<style scoped>
.Q1
{
    background-position: center;
    border-radius: 25px;
    padding: 10px 20px;
    align-content: center;
    position: absolute;
    left: 50%;
    top:27%;
    transform: translate(-50%, -15%);
    width: 80%;
    background-color: none!important;
}

.column1
{
    flex-basis: 32%;
    margin: 15px;
    padding: 20px 12px;
    margin-right: 0px;
    padding-right: 0px;
    box-sizing: border-box;
    align-self: center;
}

.column2
{
    flex-basis: 68%;
    margin: 15px;
    padding: 20px 12px;
    text-align: -webkit-center;
    margin-left: 0px;
    padding-left: 0px;
}

.card
{
    background: white;
    border-radius: 15px;
    box-sizing: border-box;
    box-shadow: 0 0 25px 0px rgba(0,0,0,0.5);
    width: 85%;
    /* height: 85%; */
    padding: 38px;
    max-height: 1000px;
}

.resultrow
{
    display: flex;
    justify-content: center;

}

.card-column
{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin: auto;
    flex-wrap: wrap;
    align-self: center;
}

.title_img
{
    width: 57%;
    margin-top: 0px;
}

.card_img
{
    width: 20%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.fortextonly
{
    color: white; 
    margin-top: 20px; 
    font-size: 23px; 
    font-weight: 800; 
    letter-spacing: 0.7px;
}

@media(max-width: 1200px)
{
    .Q1
    {
        width: 100%;
        padding: 0px;
    }
}


@media(max-width: 800px)
{

    .title_img
    {
        width: 55%;
        margin-top: 0px;
    }

    .column1
    {
        margin: 0px;
        padding: 0px 12px 0px 12px;
        box-sizing: border-box;
    }

    .column2
    {
        width: 100%;
        box-sizing: border-box;
    }
    .resultrow
    {
        display: initial;
        margin-top: 3%;
        flex-wrap: wrap;
        width: 100%;
    }

}


@media(max-width: 500px)
{
    .Q1
    {
        position: absolute;
        left: 50%;
        top:45%;
        transform: translate(-50%, -15%);
        width: 85%;
        padding: 0px;
    }
    .resultrow
    {
        display: initial;
    }

    .column2
    {
        width: 130%;
        box-sizing: border-box;
        margin-left: -25px;
        padding-left: 0px;
        margin-right: 0px;
        padding-right: 38px;
    }
    .card_img
    {
        width: 40%;
    }
    .card
    {
        background: white;
        border-radius: 15px;
        box-sizing: border-box;
        height: 100%;
        box-shadow: 0 0 25px 0px rgba(0,0,0,0.5);
        width: 100%;
        max-height: 100%;
        padding: 20px;
    }

    .fortextonly
    {
        font-size: 20px;
        letter-spacing: 0.5px;
    }



}

</style>