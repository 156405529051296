<template>
  <div>
    <section class="topheader">
        <a href="javascript:window.location.reload(true)"><img alt="logo" class="logoimg" src="./assets/logo-1.png"></a>
        <img alt="logo" class="logoimg" src="./assets/logo-2.png" style="float:right;">
    </section>
    <PageNotFound v-if='notFound'/>
    <MultiPage v-else />
    <!-- <ThankYou /> -->
  </div>
</template>

<script>
import MultiPage from './components/steps/MultiPage.vue'
import { ref } from 'vue'
import PageNotFound from './components/steps/PageNotFound.vue'
import axios from 'axios'
// import Question from './components/steps/Question.vue'
// import ResultPage from './components/steps/ResultPage.vue'
// import ThankYou from './components/steps/ThankYou.vue'


export default {
  name: 'App',
  components: {
    PageNotFound,
    MultiPage,
    // Question,
    // ResultPage
    // ThankYou
  },
  setup() {
    const notFound = ref(false)
    // axios.post(`${API_URL}/survey/get-question`, {
    axios.post(`https://panel.engagelife.com.my/survey/get-question?slug=${window.location.pathname.replace(/\//, '')}`, {
      
    }, {
      headers: {
        "Content-Type": "text/plain"
      }
    })
    .then((res) => {

      const {status} = res.data
      // console.log(res, status, data)
        notFound.value = !status // check here
    })
    .catch(err => {
      console.log(err)
      notFound.value = true
    })
    .finally(() => {

    })

    // expose to template and other options API hooks
    return {
      notFound
    }
  },
  data(){
    return{
      result: false,
      submitqna: [

      ]
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}

body
{
  margin: 0;
  background-image: url(assets/bg.png);
  width: 100%;
  background-position: center;
  background-size: 3000px 3000px;
}

.topheader
{
  background-color: white;
  height: 75px;
  width: 100%;
  position: relative;
  margin:0%;
}

.logoimg
{
  margin:15px 20px 0px 20px;
  float: left;
  width:8%;
}

@media(max-width: 1950px)
{
  .logoimg
    {
      margin:18px;
      float: left;
      width: 6%;
    }
}

@media(max-width: 1550px)
{
  .logoimg
    {
      margin:18px;
      float: left;
      width: 8.5%;
    }
}


@media(max-width: 850px)
{
    .logoimg
    {
      margin:20px;
      float: left;
      width: 15%;
    }
}

@media(max-width: 450px)
{
    .logoimg
    {
      margin:20px;
      float: left;
      width: 25%;
    }

}

</style>
