<template>
    <div v-if="action == 'qna'" style="justify-content: center" class="Q1">
      <ul v-for="q in qna" :key="q.id" v-show="q.id == questionstep">
        <h1 style="font-weight: bold; color: #110093">Q{{ q.number }}</h1>
        <h2>
          <b> {{ q.title }} </b>
        </h2>
        <div v-html="q.html"></div>

        <!-- Multiple-select checkboxes -->
        <template v-if="q.input_type == 'checkbox_multiple'">
          <div v-for="ans in q.answers" class="form-check" :key="ans.id">
            <label class="form-check-label option-style" :for="ans.id">
              <input
                class="form-check-input"
                type="checkbox"
                name="answers"
                :id="ans.id"
                :value="ans.id"
                v-model="selectedAnswers"
                @change="handleCheckboxChange(ans)"/>
              {{ ans.content }}

            <!-- Free text input for "Others" option -->
            <input
              v-if="ans.input_type == 'checkbox_freetext' && selectedAnswers.includes(ans.id)"
              type="text"
              id="inputfield_for_freetext"
              class="freetext_field"
              v-model="submitqna_intext[ans.id]"
              placeholder="Please specify"
              @input="disabledbtn"/>
            </label>
          </div>
        </template>

        <!-- Single-select radio buttons -->
        <template v-else>
          <div v-for="ans in q.answers" :key="ans.id" class="form-check">
            <label class="form-check-label option-style" :for="ans.id">
              <input
                class="form-check-input"
                type="radio"
                name="answers"
                :id="ans.id"
                :value="ans.id"
                v-model="newans"
                @change="getInputType(ans)"/>
              {{ ans.content }}

            <!-- Free text input for radio "Others" option -->
            <input
              v-if="ans.input_type == 'checkbox_freetext' && newans == ans.id"
              type="text"
              id="inputfield_for_freetext"
              class="freetext_field"
              v-model="submitqna_intext[ans.id]"
              placeholder="Please specify"
              @input="disabledbtn"/>
            </label>
          </div>
        </template>

        <button
          @click="handleNext"
          class="next-btn"
          v-if="questionstep < qna.length"
          type="button">
          Next
        </button>

        <button
          @click="handleSubmit"
          class="next-btn"
          type="button"
          v-if="questionstep == qna.length"
          :disabled="btndisabled">
          Get the Result
          <div class="spinner-border spinner-border-sm" role="status" v-if="loading"></div>
        </button>
      </ul>
    </div>

    <ResultPage v-if="action == 'completed'" :storedresult="this.results" />
    <ThankYouOne v-if="nextAction == 'terminated'" />
    <ThankYouTwo v-if="action == 'multiple'" />
  </template>

<script>
import axios from "axios";
import ThankYouOne from "./ThankYouOne.vue";
import ThankYouTwo from "./ThankYouTwo.vue";
import ResultPage from "./ResultPage.vue";

export default {
  name: "SurveyQuestion",
  components: {
    ThankYouOne,
    ResultPage,
    ThankYouTwo,
  },

  data() {
    return {
      qna: [],
      questionstep: 1,
      loading: false,
      btndisabled: true,
      other_ans:'',
      submitqna: [],
      submitqna_intext: {},
      slug: "",
      action: "qna",
      results: "",
      newans: null,
      selectedAnswers: [],
    };
  },

  computed: {
    answers() {
      return this.qna.flatMap((q) => q.answers);
    },
    nextAction() {
      return this.answers.reduce((action, ans) => {
        if (!action) {
          return this.submitqna.find((e) => e === ans.id)
            ? ans.next_action
            : null;
        }

        return action;
      }, null);
    },
  },
  methods: {
    showloading() {
      this.loading = true;
    },

    handleNext() {
      if (!this.validateAnswers()) return;

      this.storeAns();

      // Move to the next question
      this.questionstep += 1;

      // Reset button state for the next question
      this.btndisabled = true;

      // Clear the current answers after moving to the next question
      this.newans = null;
      this.selectedAnswers = [];
      this.submitqna_intext = {};
    },

    validateAnswers() {
      if (this.newans === null && this.selectedAnswers.length === 0) {
        alert("Please select at least one answer.");
        return false;
      }

      // Check if 'Others' option is selected and requires a free text input
      for (let ansId in this.submitqna_intext) {
        if (Object.prototype.hasOwnProperty.call(this.submitqna_intext, ansId)) {
          if (!this.submitqna_intext[ansId].trim()) {
            alert("Please fill in the required 'Others' field.");
            return false;
          }
        }
      }

      return true;
    },

    disabledbtn() {
      this.btndisabled = false;
    },

    getInputType(ans) {
      this.newans = ans.id;
      if (ans.input_type === "checkbox_freetext") {
        this.disabledbtn();
      } else {
        this.btndisabled = false;
      }
    },

    handleCheckboxChange(ans) {
      if (ans.input_type !== "checkbox_freetext") return;
      if (!this.selectedAnswers.includes(ans.id)) {
        this.$delete(this.submitqna_intext, ans.id);
      }
      this.disabledbtn();
    },

    storeAns() {
      let answers = [];

      // Add single-select answer
      if (this.newans !== null && this.newans !== '') {
        answers.push(this.newans);
      }

      // Add multi-select answers
      if (this.selectedAnswers.length > 0) {
        answers = answers.concat(this.selectedAnswers.filter(ans => ans !== null && ans !== ''));
      }

      // Clean up any empty answers and join them
      let cleanedAnswers = answers.join(',');

      // Separate the "Others" free text input
      let othersText = '';

      for (let ansId in this.submitqna_intext) {
        if (Object.prototype.hasOwnProperty.call(this.submitqna_intext, ansId) && this.submitqna_intext[ansId].trim() !== '') {
          othersText = this.submitqna_intext[ansId].trim();  // Store the "Others" free text input
          break;  // Assume there is only one "Others" input, so we can break after finding it
        }
      }
      console.log(othersText,'jhere');
      // Push the cleaned, formatted answer string
      this.submitqna.push(cleanedAnswers);
      if(othersText){
          this.other_ans = othersText;
      }
      return othersText;
    },

    scrollTop() {
      window.scrollTo(0, 0);
    },

    async handleSubmit() {

     this.storeAns();
      // Create query string parameters
      const params = new URLSearchParams();
      params.append('qna', this.submitqna.join(','));
      params.append('text', this.other_ans || '');
      params.append('slug', window.location.pathname.replace(/\//, ''));

      await axios({
        method: "post",
        url: `https://panel.engagelife.com.my/survey/submit-result?${params.toString()}`,
        headers: {
          "Content-Type": "text/plain"
        }
      }).then(
        (response) => {
          this.action = response.data.data.action;
          this.results = response.data.data.results;
        }
      );

      console.log(this.action);
      console.log(this.results);
    },
  },

  mounted() {
    axios({
      method: "post",
      url: `https://panel.engagelife.com.my/survey/get-question?slug=${window.location.pathname.replace(/\//, '')}`,
      headers: {
        "Content-Type": "text/plain"
      }
    }).then((response) => (this.qna = response.data.data.qna));
  },
};
</script>
<style scoped>
.font-style {
  color: red;
}

ul {
  list-style: none;
  padding: 0;
  margin-left: 60px;
  margin-right: 60px;
}

.next-btn {
  background-color: #110093;
  border: none;
  color: white;
  border-radius: 30px;
  font-weight: bold;
  margin: 20px 10px 10px 10px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 30px;
  padding: 12px 32px;
}


.Q1 {
  padding: 40px 30px 20px 30px;
}

.option-style {
  background-color: #f5f5f5;
  margin: 10px 0px 10px 0px;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 18px;
  padding-left: 45px;
  border-radius: 30px;
  width: 100%;

  cursor: pointer;
}

.disablebtncolor
{
    background-color: black;
}

.option_content
{
  text-align: justify;
  margin-left: 10px;
}

.form-check
{
  padding-left: 0px;
}

.spinner-border-sm
{
    --bs-spinner-width: 1.2rem;
    --bs-spinner-height: 1.2rem;
    --bs-spinner-border-width: 0.2em;
    margin-left: 10px;
}

.freetext_field{
  border-radius: 20px;
  height:45px;
  width:100%;
  margin-left:20px;
  margin: 15px 0 0 0;
  border:none;
  padding-left:20px;
  box-shadow: inset -2px -2px 7px rgba(255, 255, 255, 0.8),
           inset 2px 2px 7px rgba(23, 23, 23, 0.3);
}


@media (max-width: 650px) {

  .Q1 {
    margin: 0px;
    padding: 20px;
    padding-top: 35px;
  }
  .next-btn {
    background-color: #110093;
    border: none;
    color: white;
    border-radius: 30px;
    font-weight: bold;

    font-size: 18px;
    font-weight: 700;
    border-radius: 30px;
    padding: 12px 28px;
  }
  ul {
    margin-left: 25px;
    margin-right: 25px;
}
}

@media(max-width: 500px)
{
  .option_content
  {
    text-align: left;
  }
}

</style>